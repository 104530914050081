import React from "react";
import s from "./CreateSubject.module.css";

const CreateSubject = (props) => {
    return (
        <div className={s.create_subject_container}>
            <h1 className={s.create_subject__title}>Создайте предмет</h1>
            <div className={s.create_subject_content}>
                <form
                    className={s.create_subject_form}
                    onSubmit={props.handleCreateSubject}
                >
                    <fieldset>
                        <ul>
                            <li>
                                <label htmlFor="subjectname">
                                    name
                                </label>
                                <input
                                    type="text"
                                    name="subjectname"
                                    value={props.subjectName || ""}
                                    onChange={props.handleChangeSubjectName}
                                    required
                                />
                            </li>
                            <li>
                                <label htmlFor="shortSubjectName">
                                    shortname
                                </label>
                                <input
                                    type="text"
                                    name="shortSubjectName"
                                    value={props.shortSubjectName || ""}
                                    onChange={props.handleChangeShortSubjectName}
                                    required
                                />
                            </li>
                            <li>
                                <label htmlFor="subjectPathName">
                                    pathname
                                </label>
                                <input
                                    type="text"
                                    name="subjectPathName"
                                    value={props.pathName || ""}
                                    onChange={props.handleChangePathName}
                                    required
                                />
                            </li>
                            <li>
                                <label htmlFor="course">course</label>
                                <select
                                    name="course"
                                    value={props.course || ""}
                                    onChange={props.handleChangeCourse}
                                >
                                    <option value="" disabled hidden>
                                        Выберите курс
                                    </option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </li>
                        </ul>
                        <button type="submit">Создать</button>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export default CreateSubject;
