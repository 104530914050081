const SET_SUBJECTS = "SET_SUBJECTS"
const SET_SUBJECTS_FETCHING = "SET_SUBJECTS_FETCHING"


let initialState = {
    subjects: [],
    isFetching: true
};

const subjectMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            };
        case SET_SUBJECTS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        default:
            return state;
    }
};

export const setSubjects = (subjects) => {
    return {type: SET_SUBJECTS, payload: subjects};
};

export const setSubjectsFetching = (isFething) => {
    return {type: SET_SUBJECTS_FETCHING, payload: isFething};
};


export default subjectMenuReducer;
