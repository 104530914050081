const SET_SUBJECTS = "SET_SUBJECTS"
const SET_FETCHING = "SET_FETCHING"
const ADD_FILES = "ADD_FILES"
const REMOVE_FILE = "REMOVE_FILE"
const SET_SAVING_PATH = "SET_SAVING_PATH";

let initialState = {
    subjects: [],
    files: {},
    isFetching: true
};

const ModerationFilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            };
        case ADD_FILES:
            const {subject, files} = action.payload;
            const subjectFiles = state.files[subject] || [];
            const updatedFiles = [
                ...subjectFiles,
                ...files.filter(file => !subjectFiles.some(f => f.id === file.id))
            ];
            return {
                ...state,
                files: {
                    ...state.files,
                    [subject]: updatedFiles
                }
            };
        case REMOVE_FILE:
            const {sub, fileId} = action.payload;
            const updFiles = state
                .files[sub]
                .filter(file => file.id !== fileId);
            return {
                ...state,
                files: {
                    ...state.files,
                    [sub]: updFiles
                }
            };
            case SET_SAVING_PATH: {
                const { subj, fId, savingPath } = action.payload;
                
                // Строим путь из массива, начиная со второго элемента (пропуская rootFolder)
                const pathString = subj+savingPath.slice(1).reduce((path, part) => `${path}/${part.filename}`, '');
              
                const updatedFls = state.files[subj].map(file => {
                  if (file.id === fId) {
                    // Обновляем файл, добавляя строку пути в savingPath
                    return {
                      ...file,
                      savingPath: pathString // здесь уже храним строку, а не массив
                    };
                  }
                  return file;
                });
                
                return {
                  ...state,
                  files: {
                    ...state.files,
                    [subj]: updatedFls
                  }
                };
              }
                  
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        default:
            return state;
    }
};

export const setSubjects = (subjects) => {
    return {type: SET_SUBJECTS, payload: subjects};
};

export const addFiles = (subject, files) => {
    return {
        type: ADD_FILES,
        payload: {
            subject,
            files
        }
    };
};

export const removeFile = (sub, fileId) => {
    return {
        type: REMOVE_FILE,
        payload: {
            sub,
            fileId
        }
    };
};

export const setSavingPath = (subj, fId, savingPath) => {
    return {
        type: SET_SAVING_PATH,
        payload: {
            subj,
            fId,
            savingPath
        }
    };
};

export const setSubjectsFetching = (isFething) => {
    return {type: SET_FETCHING, payload: isFething};
};

export default ModerationFilesReducer;
