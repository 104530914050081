import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
    setContent,
    setSubjectName,
    setFetching,
    setPath,
    setCourse,
} from "../../redux/subjectReducer";
import Subject from "./Subject";
import RenderContent from "../assets/RenderContent";
import axios from "axios";
import { server } from "../../server-config";
import s from "./Subject.module.css";

const SubjectContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const subject = useParams().subjectName;
    const folderId = useParams().folder;

    const getCourse = async (subject) => {
        try {
            const response = await axios.get(
                `${server}/api/get-course?subject=${subject}`,
                {
                    subject: subject,
                }
            );
            if (response.status === 200) {
                dispatch(setCourse(response.data[0].course));
            } else {
                console.error("Failed get course");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const course = useSelector((state) => state.subjectReducer.course);

    useEffect(() => {
        getCourse(subject);
    }, []);

    const fetchData = async (folderId, subject) => {
        if (!folderId) {
            navigate("/not-found", { replace: true });
            return;
        }
        let isCancelled = false;
        dispatch(setFetching(true));
        try {
            const [
                contentResponse,
                subjectNameResponse,
                pathResponse,
            ] = await Promise.all([
                axios.get(
                    `${server}/api/get-data?subject=${subject}&folderId=${folderId}`
                ),
                axios.get(`${server}/api/get-subjectName?folderId=${folderId}`),
                axios.get(
                    `${server}/api/get-path?subject=${subject}&id=${folderId}`
                ),
            ]);
            if (!contentResponse.data || !subjectNameResponse.data) {
                navigate("/not-found", { replace: true });
                return;
            }
            if (!isCancelled) {
                getCourse(subject);
                dispatch(setContent(contentResponse.data));
                dispatch(setSubjectName(subjectNameResponse.data));
                dispatch(setPath(pathResponse.data));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            navigate("/not-found", { replace: true });
        } finally {
            if (!isCancelled) {
                dispatch(setFetching(false));
            }
        }

        return () => {
            isCancelled = true;
        };
    };

    useEffect(() => {
        fetchData(folderId, subject);
    }, [folderId, subject]);

    const [isRoleLoaded, setIsRoleLoaded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const role = useSelector((state) => state.sessionReducer.user.role);
    useEffect(() => {
        setIsRoleLoaded(true);
    }, [role]);
    useEffect(() => {
        if (isRoleLoaded && role === "admin") {
            setIsAdmin(true);
        }
    }, [role, isRoleLoaded]);

    const [editShortName, setEditShortName] = useState("");
    const [editableFileId, setEditableFileId] = useState(null);
    const handleEditName = async (item) => {
        try {
            const response = await axios.put(`${server}/api/update-file-name`, {
                id: editableFileId,
                shortName: editShortName,
                subjectFullName: subjectName,
            });

            if (response.status === 200) {
                setEditShortName(""); // Очищаем поле ввода editShortName
                setEditableFileId(null);
                fetchData(folderId, subject);
            } else {
                console.error("Failed to update file shortName");
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setEditableFileId(null);
    };
    const toggleEdit = (item) => {
        if (editableFileId === item.id) {
            setEditableFileId(null);
        } else {
            setEditShortName(item.shortName);
            setEditableFileId(item.id);
        }
    };

    const content = useSelector((state) => state.subjectReducer.content);
    const subjectName = useSelector(
        (state) => state.subjectReducer.subjectName
    );
    const isFetching = useSelector((state) => state.subjectReducer.isFetching);
    const path = useSelector((state) => state.subjectReducer.path);
    let displayPath = [
        <React.Fragment key="course-link">
            <NavLink key={`${course}-link`} to={`/subject-menu/${course}/`}>
                {course} курс
            </NavLink>
            /
        </React.Fragment>,
        ...path.map((item, index) => {
            if (index === 0) {
                return (
                    <React.Fragment key={item.pathname + item.id}>
                        <NavLink
                            key={item.pathname}
                            to={`/subject/${subject}/${item.rootFolder}`}
                        >
                            {item.shortName}
                        </NavLink>
                        /
                    </React.Fragment>
                );
            } else {
                if (index === path.length - 1) {
                    return (
                        <NavLink
                            key={item.id}
                            to={`/subject/${subject}/${item.content}`}
                        >
                            {item.filename}
                        </NavLink>
                    );
                } else {
                    return (
                        <React.Fragment key={item.id}>
                            <NavLink
                                key={item.id}
                                to={`/subject/${subject}/${item.content}`}
                            >
                                {item.filename}
                            </NavLink>
                            /
                        </React.Fragment>
                    );
                }
            }
        }),
    ];

    let sortedContent = content.slice().sort((a, b) => {
        if (a.icontype === "folder" && b.icontype !== "folder") {
            return -1; // Перемещаем элемент с icontype === 'folder' выше
        } else if (a.icontype !== "folder" && b.icontype === "folder") {
            return 1; // Перемещаем элемент с icontype !== 'folder' ниже
        } else {
            return 0;
        }
    });
    let isEmptyFolder = true;
    let items = sortedContent.map((item) => {
        if (item.status !== "confirmed") {
            return null;
        } else {
            isEmptyFolder = false;
        }
        return (
            <div className={s.subject__center} key={item.id}>
                {item.icontype === "articles" ? (
                    <div>
                        <a
                            href={`${server}/api/download?subject=${subject}&fileId=${item.id}`}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <RenderContent
                                icon={item.icontype}
                                name={item.filename}
                            />
                        </a>
                    </div>
                ) : (
                    <div>
                        <NavLink
                            to={`/subject/${subject}/${item.content}`}
                            key={item.content}
                        >
                            <RenderContent
                                icon={item.icontype}
                                name={item.filename}
                            />
                        </NavLink>
                    </div>
                )}
                {isAdmin && (
                    <button
                        className={s.file_edit_button}
                        onClick={() => toggleEdit(item)}
                    >
                        Edit Name
                    </button>
                )}
                <div className={s.file_editing_menu}>
                    {editableFileId === item.id ? (
                        <input
                            type="text"
                            value={editShortName || ""}
                            onChange={(e) => setEditShortName(e.target.value)}
                        />
                    ) : (
                        ""
                    )}
                    {editableFileId === item.id && (
                        <button
                            className={s.save_button}
                            onClick={() => handleEditName(item)}
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
        );
    });

    if (isEmptyFolder) {
        items = <p>Тут пока ничего нету</p>;
    }
    return (
        <Subject
            items={items}
            subjectName={subjectName}
            displayPath={displayPath}
            isFetching={isFetching}
        />
    );
};

export default SubjectContainer;
