import {Routes, Route} from "react-router-dom";
import "./App.css";
import HeaderContainer from "./components/Header/HeaderContainer";
import MainPage from "./components/MainPage/MainPage";
import SubjectMenuContainer from "./components/SubjectMenu/SubjectMenuContainer";
import SubjectContainer from "./components/Subjects/SubjectContainer"
import AuthPageContainer from "./components/AuthPage/AuthPageContainer";
import CreateUserContainer from "./components/AuthPage/CreateUserContainer";
import SessionChecker from './components/SessionChecker';
import UploadMaterialsContainer from "./components/UploadMaterials/UploadMaterialsContainer";
import CreateFolder from "./components/CreateFolder/CreateFolder"
import NotFoundPage from "./components/assets/NotFoundPage";
import ModerationFiles from "./components/Moderation/ModerationFiles";
import CourseMenu from "./components/SubjectMenu/CourseMenu";
import CreateSubjectContainer from "./components/CreateSubject/CreateSubjectContainer";

function App() {
    return (
        <div className="app-container">
            <SessionChecker/>
            <HeaderContainer/>
            <main className="main">
                <div className="container main__container">
                    <div className="inner">
                        <Routes>
                            <Route path="/" element={< MainPage />}/>
                            <Route path="course-menu/" element={< CourseMenu />}/>
                            <Route path="subject-menu/:course" element={< SubjectMenuContainer />}/>
                            <Route path="/subject/:subjectName/:folder" element={< SubjectContainer />}/>
                            <Route path="login" element={< AuthPageContainer />}/>
                            <Route path="registration" element={< CreateUserContainer />}/>
                            <Route path="/upload-materials" element={< UploadMaterialsContainer />} />
                            <Route path="/create-folder" element={< CreateFolder/>} />
                            <Route path="/moderation" element={< ModerationFiles/>} />
                            <Route path="/create-subject" element={< CreateSubjectContainer />} />
                            <Route path="*" element={< NotFoundPage />}/>
                        </Routes>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default App;