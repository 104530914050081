import {configureStore} from "@reduxjs/toolkit";
import subjectReducer from "./subjectReducer";
import subjectMenuReducer from "./subjectMenuReducer";
import createUserReducer from "./createUserReducer";
import authReducer from "./authReducer"
import sessionReducer from "./sessionReducer";
import uploadReducer from "./uploadReducer";
import createFolderReducer from "./createFolderReducer";
import ModerationFilesReducer from "./moderationFilesReducer";

const store = configureStore({
    reducer: {
        subjectReducer: subjectReducer,
        subjectMenuReducer: subjectMenuReducer,
        createUserReducer: createUserReducer,
        authReducer: authReducer,
        sessionReducer: sessionReducer,
        uploadReducer: uploadReducer,
        createFolderReducer: createFolderReducer,
        ModerationFilesReducer: ModerationFilesReducer
    }
});

export default store;
