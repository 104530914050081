import React from 'react';
import {useEffect} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import CreateUser from './CreateUser';
import {setUsername, setPassword, setError, setSuccess} from '../../redux/createUserReducer'
import { server } from '../../server-config';

const CreateUserContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.createUserReducer);

    const handleUsernameChange = (event) => {
        dispatch(setUsername(event.target.value));
        dispatch(setError());
        dispatch(setSuccess());
    };

    const handlePasswordChange = (event) => {
        dispatch(setPassword(event.target.value));
        dispatch(setError());
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        const {username, password} = state;

        if (!username || !password) {
            dispatch(setError({code: 406, message: 'Вы должны ввести логин и пароль'}));
            return;
        }

        try {
            const response = await axios.post(`${server}/api/create-user`, {
                username: username,
                password: password
            });

            if (response.status === 200) {
                dispatch(setSuccess({code: 200, message: 'Пользователь успешно создан!'}));
                dispatch(setUsername(''));
                dispatch(setPassword(''));
                return;
            }

        } catch (error) {
            if (error.response.status === 400) {
                dispatch(setError({code: 400, message: 'Пользователь с таким логином уже существует'}));
            } else if (error.response.status === 401) {
                dispatch(setError({code: 401, message: 'Необходимо указать username и password'}));
            } else if (error.response.status === 402) {
                dispatch(setError({code: 402, message: 'Логин должен содержать только латинские символы и цифры, длиной до 16 символов'}));
            } else if (error.response.status === 403) {
                dispatch(setError({code: 403, message: 'Пароль должен содержать не менее 6 символов'}));
            } else if (error.response.status === 500) {
                dispatch(setError({code: 500, message: 'Ошибка созданиия пользователя'}));
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setError());
            dispatch(setSuccess());
            dispatch(setUsername(""));
            dispatch(setPassword(""));
        };
    }, [dispatch]);

    return <CreateUser
        state={state}
        handleUsernameChange={handleUsernameChange}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}/>;
};

export default CreateUserContainer;
