import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import {setSubjects, setTree, setFetching, setIsCreated} from "../../redux/createFolderReducer";
import Preloader from "../assets/Preloader";
import FolderTree from './FolderTree';
import { server } from "../../server-config";

const CreateFolder = (props) => {
    const [isRoleLoaded,
        setIsRoleLoaded] = useState(false);
    const navigate = useNavigate();
    const role = useSelector(state => state.sessionReducer.user.role);

    useEffect(() => {
        setIsRoleLoaded(true);
    }, [role]);
    useEffect(() => {
        if (isRoleLoaded && role !== "admin") {
            navigate("/not-found");
        }
    }, [role, navigate, isRoleLoaded]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isRoleLoaded && role !== "admin") {
            navigate("/not-found");
        } else if (isRoleLoaded && role === "admin") {
            const fetchSubjects = async () => {
                try {
                    const courses = [1, 2, 3, 4];
                    const responses = await Promise.all(courses.map(async (course) => {
                        return await axios.get(`${server}/api/get-subjects?course=${course}`);
                    }));
                    
                    const subjects = [];
                    responses.forEach(response => {
                        subjects.push(...response.data); // Добавляем данные из каждого ответа в массив subjects
                    });
            
                    if (subjects.length > 0) {
                        dispatch(setSubjects(subjects)); // Диспатчим массив subjects
                    }
                    
                    dispatch(setFetching(false));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchSubjects();
        }
    }, [role, navigate, isRoleLoaded, dispatch]);

    const isFetching = useSelector(state => state.createFolderReducer.isFetching);
    const subjects = useSelector(state => state.createFolderReducer.subjects);
    const tree = useSelector(state => state.createFolderReducer.tree);
    const isCreated = useSelector(state => state.createFolderReducer.isCreated);
    useEffect(() => {
        const fetchTree = async() => {
            const treeData = [];

            for (const subject of subjects) {
                try {
                    if (subject.pathName){
                        const response = await axios.get(`${server}/api/get-folder-tree?subject=${subject.pathName}`);
                        treeData.push(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

            dispatch(setTree(treeData));
            dispatch(setFetching(false));
            dispatch(setIsCreated(false));
        };

        fetchTree();
    }, [dispatch, subjects, isCreated]);

    return (
        <Preloader isFetching={isFetching}>
            <div>
                <h1>Управление папками</h1>
                <FolderTree treeData={tree}/>
            </div>
        </Preloader>
    );
};

export default CreateFolder;
