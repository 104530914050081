import React from "react";
import { NavLink } from "react-router-dom";
import s from "./SubjectMenu.module.css";
import Preloader from "../assets/Preloader";

const SubjectMenu = (props) => {
    return (
        <Preloader isFetching={props.isFetching}>
            <div className={s.subject__content}>
                <h1 className={s.subject__title}>Выберите предмет</h1>
                <div className={s.subject_menu_path}>
                    <NavLink key="select_course" to="/course-menu">
                        Выбрать курс
                    </NavLink>
                </div>
                <div className={s.subject__sections}>
                    {props.subjectsItems}
                </div>
            </div>
        </Preloader>
    );
};

export default SubjectMenu;