const SET_USER_INFO = "SET_USER_INFO";
const SET_TOKEN = "SET_TOKEN";

let initialState = {
    user: {
        authorized: false,
        username: null,
        role: null
    },
    token: null
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                user: {
                    ...state.user,
                    authorized: action.payload.authorized,
                    username: action.payload.username,
                    role: action.payload.role,
                }
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        default:
            return state;
    }
};

export const setUserInfo = (username, authorized, role) => {
    return {type: SET_USER_INFO, payload: {username, authorized, role}};
};

export const setToken = (token) => {
    return {type: SET_TOKEN, payload: token};
};

export default sessionReducer;
