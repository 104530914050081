import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    setFile,
    setInputFileName,
    setCourse,
    setSubjects,
    setSubject,
    setFolders,
    setFolderName,
    setError,
    clearForm,
    setSuccess,
    setFolderId
} from "../../redux/uploadReducer";
import axios from 'axios';
import UploadMaterialsPage from "./UploadMaterials";
import { server } from "../../server-config";

const UploadMaterialsPageContainer = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.uploadReducer);
    const [userFile,
        setUserFile] = useState(null);

    useEffect(() => {
        if (state.currentSubject) {
            axios
                .get(`${server}/api/get-folder-tree?subject=${state.currentSubject}`)
                .then(response => {
                    dispatch(setFolders(response.data));
                })
                .catch(error => {
                    console.error('Error fetching folders', error);
                });
        }
    }, [dispatch, state.currentSubject]);

    const [isDataReady,
        setIsDataReady] = useState(false);

    useEffect(() => {
        if (userFile && state.inputFileName && state.file.fileUrl && state.currentSubject && state.currentFolderName) {
            setIsDataReady(true);
        } else {
            setIsDataReady(false);
        }
    }, [userFile, state.inputFileName, state.file.fileUrl, state.currentSubject, state.currentFolderName]);

    const handleUpload = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', userFile); // добавляем файл в FormData
        formData.append('fileName', `${state.inputFileName}.${userFile.name.split('.').pop()}`);
        formData.append('content', state.file.fileUrl);
        formData.append('subject', state.currentSubject);
        formData.append('currentFolderName', state.currentFolderName);
        formData.append('currentFolderId', state.currentFolderId);


        try {
            await axios.post(`${server}/api/upload-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            dispatch(setSuccess({code: 200, message: 'Файл успешно отправлен на модерацию! Он появится после подтверждения администрацией'}));
            dispatch(clearForm());
        } catch (error) {
            if (error.response.status === 413) {
                dispatch(setError({code: 413, message: 'Ошибка: файл слишком большой. Размер файла не должен превышать 70 МБ'}));
                dispatch(clearForm());
            }
            else {
                dispatch(setError({code: 500, message: 'Ошибка загрузки файла'}));
            }
            }
        };

    const allowedExtensions = [
        'pdf',
        'doc',
        'docx',
        'nma',
        'txt',
        'djvu',
        'c',
        'py',
        'cpp',
        'xls',
        'xlsx',
        'rar',
        'zip'
    ];
    const handleFileChange = (e) => {
        const uploadFile = e.target.files[0];
        if (uploadFile) {
            const fileExtension = uploadFile
                .name
                .split('.')
                .pop(); // Получаем расширение файла
            if (!allowedExtensions.includes(fileExtension)) {
                dispatch(setError({code: 400, message: `Недопустимый формат файла. Разрешены только ${allowedExtensions}`}));
                return; // Не загружаем файл и выводим ошибку
            }
            setUserFile(uploadFile);
            const uploadFileUrl = URL.createObjectURL(uploadFile);
            dispatch(setFile({fileUrl: uploadFileUrl, filename: `${uploadFile.name}`}));
            dispatch(setError());
            dispatch(setSuccess());
        }
    };


    const handleInputFileNameChange = (event) => {
        dispatch(setInputFileName(event.target.value));
        dispatch(setError());
        dispatch(setSuccess());
    };

    const handleGetSubjects = () => {
        // dispatch(setSubject([]))
        // if (state.subjects.length === 0) {
            axios
                .get(`${server}/api/get-subjects?course=${state.course}`)
                .then(response => {
                    dispatch(setSubjects(response.data));
                })
                .catch(error => {
                    console.error('Error fetching subjects', error);
                });
        // }
    }

    const handleCourseChange = (event) => {
        dispatch(setCourse(Number(event.target.value)));
        dispatch(setSubjects([]));
        dispatch(setFolders([]));
        dispatch(setSubject(""));
        dispatch(setFolderId(""));
        dispatch(setFolderName(""));
        dispatch(setError());
        dispatch(setSuccess());
    };

    const handleSubjectChange = (event) => {
        dispatch(setSubject(event.target.value));
        dispatch(setError());
        dispatch(setSuccess());
    };

    const handleFolderChange = (event) => {
        const selectedOption = event.target.selectedOptions[0];
        const folderId = selectedOption.getAttribute('data-folderid');
        dispatch(setFolderName(event.target.value));
        dispatch(setFolderId(folderId));
        dispatch(setError());
        dispatch(setSuccess());
    };

    useEffect(() => {
        return () => {
            dispatch(clearForm());
        };
    }, [dispatch]);
    
    const folders = useSelector(state => state.uploadReducer.folders);

    return (<UploadMaterialsPage
        handleUpload={handleUpload}
        handleFileChange={handleFileChange}
        handleInputFileNameChange={handleInputFileNameChange}
        handleCourseChange={handleCourseChange}
        handleSubjectChange={handleSubjectChange}
        handleFolderChange={handleFolderChange}
        handleGetSubjects={handleGetSubjects}
        folders={folders}
        state={state}
        isDataReady={isDataReady}/>);
};

export default UploadMaterialsPageContainer;
