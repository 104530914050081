import React from 'react';
import axios from 'axios';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AuthPage from './AuthPage';
import {setUserInfo, setToken} from '../../redux/sessionReducer';
import {setUsername, setPassword, setError, setSuccess} from '../../redux/authReducer'
import { server } from '../../server-config';

const AuthPageContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector((state) => state.authReducer);
    const handleUsernameChange = (event) => {
        dispatch(setUsername(event.target.value));
        dispatch(setError());
        dispatch(setSuccess());
    };

    const handlePasswordChange = (event) => {
        dispatch(setPassword(event.target.value));
        dispatch(setError());
    };
    const handleSubmit = async(event) => {
        event.preventDefault();
        const {username, password} = state;

        try {
            const response = await axios.post(`${server}/api/login`, {
                username: username,
                password: password
            });
            if (response.status === 200) {
                dispatch(setUserInfo(username, true, response.data.role));
                dispatch(setUsername(''));
                dispatch(setPassword(''));
                dispatch(setToken(response.data.token));
                localStorage.setItem('username', username);
                localStorage.setItem('jwtToken', response.data.token);
                localStorage.setItem('role', response.data.role);
                navigate('/')
                return;
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    dispatch(setError({code: 400, message: 'Пользователь не существует'}));
                } else if (error.response.status === 401) {
                    dispatch(setError({code: 401, message: 'Неверный пароль'}));
                } else {
                    dispatch(setError({code: 500, message: 'Ошибка входа'}));
                }
            } else {
                dispatch(setError({code: 500, message: 'Ошибка сетевого соединения'}));
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setError());
            dispatch(setSuccess());
            dispatch(setUsername(""));
            dispatch(setPassword(""));
        };
    }, [dispatch]);

    return <AuthPage
        state={state}
        handleUsernameChange={handleUsernameChange}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}/>;
};

export default AuthPageContainer;
