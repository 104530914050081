import React, {useRef} from "react";
import s from "./UploadMaterials.module.css";
import FolderSelect from "./FolderSelect";

const UploadMaterialsPage = (props) => {
    const fileInput = useRef(null);
    const handleChooseFile = () => {
        fileInput
            .current
            .click();
    };
    const fileCopy = JSON.parse(JSON.stringify(props.state.file));
    const fileNameCopy = fileCopy.filename
        ? fileCopy.filename
        : "Выбрать файл";
    return (
        <div>
            <h1 className={s.upload__title}>Загрузить материалы</h1>
            <div className={s.upload_form_container}>
                <div className={s.upload_form_information}>
                    {props.state.error.code && <span className={s.error_message}>{props.state.error.message}</span>}
                    {props.state.success.code && <span className={s.success_message}>{props.state.success.message}</span>}
                </div>
                <div className={s.form_container}>
                    <form
                        className={s.upload__form}
                        encType="multipart/form-data"
                        onSubmit={props.handleUpload}>
                        <fieldset>
                            <ul>
                                <li className={s.upload_li}>
                                    <label className={s.upload_label} htmlFor="file">Выберите файл</label>
                                    <label className={s.upload_file} onClick={handleChooseFile}>{fileNameCopy}</label>
                                    <input
                                        type="file"
                                        name="file"
                                        ref={fileInput}
                                        onChange={props.handleFileChange}/>
                                </li>
                                <li className={s.upload_li}>
                                    <label className={s.upload_label} htmlFor="fileName">Назовите файл</label>
                                    <input
                                        className={s.upload_input}
                                        type="text"
                                        name="fileName"
                                        placeholder="Название файла"
                                        value={props.state.inputFileName}
                                        onChange={props.handleInputFileNameChange}/>
                                </li>
                                <li className={s.upload_li}>
                                    <label className={s.upload_label} htmlFor="course">Выберите курс</label>
                                    <select
                                        className={s.upload_select}
                                        name="course"
                                        value={props.state.course || ""}
                                        onChange={props.handleCourseChange}>
                                        <option value="" disabled hidden>Выберите курс</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                    </select>
                                </li>
                                {props.state.course && (
                                    <li className={s.upload_li}>
                                    <label className={s.upload_label} htmlFor="subject">Выберите предмет</label>
                                    <select
                                        className={s.upload_select}
                                        name="subject"
                                        value={props.state.currentSubject}
                                        onClick={props.handleGetSubjects}
                                        onChange={props.handleSubjectChange}>
                                        <option value="" disabled hidden>Выберите предмет</option>
                                        {props
                                            .state
                                            .subjects
                                            .map(subject => (
                                                <option key={subject.id+subject.name} value={subject.pathName}>{subject.name}</option>
                                            ))}
                                    </select>
                                </li>
                                )}
                                {props.state.currentSubject && props.folders && (
                                   <FolderSelect 
                                        folders={props.folders} 
                                        currentFolderName={props.state.currentFolderName}
                                        currentFolderId={props.state.currentFolderId}
                                        handleFolderChange={props.handleFolderChange}
                                        />
                                )}

                            </ul>
                            <button
                                type="submit"
                                disabled={!props.isDataReady}
                                className={s.upload__button}>Загрузить</button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UploadMaterialsPage;
