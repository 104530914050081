import React from "react";
import s from "./UploadMaterials.module.css"

function isEmptyObject(obj) {
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            return false;
        }
    }
    return true;
}

function generateOptions(itemsObj, level = 0) {
  let options = [];
  // Проверяем, существует ли ключ 'children' и является ли он массивом
  if (itemsObj.hasOwnProperty('children') && Array.isArray(itemsObj.children)) {
      itemsObj.children.forEach(item => {  // Теперь обращаемся к 'itemsObj.children' как к массиву
          // Добавляем текущий элемент с его уровнем вложенности
          options.push(
              <option key={item.id} value={item.name} data-folderid={item.currentFolderId || item.rootFolder}>
                  {`${"—".repeat(level)} ${item.filename}`}
              </option>
          );
          // Если у элемента есть дети, рекурсивно добавляем их в список
          if (item.children && item.children.length) {
              options = options.concat(generateOptions(item, level + 1)); // Здесь передаём 'item', а не 'item.children'
          }
      });
  }
  return options;
}

const FolderSelect = (props) => {
    // Использование состояния для проверки, загружены ли уже данные
    const foldersLoaded = props.folders && !isEmptyObject(props.folders);
    // Генерация опций только если данные загружены
    const options = foldersLoaded ? generateOptions(props.folders) : [];
    return (
      <li className={s.upload_li}>
        <label className={s.upload_label} htmlFor="folder">Выберите папку</label>
        <select
          className={s.upload_select}
          name="folder"
          value={props.currentFolderName}
          onChange={props.handleFolderChange}>
          <option value="" disabled hidden>Выберите папку</option>
          {foldersLoaded ? options : <option value="" disabled>Загрузка...</option>}
        </select>
      </li>
    );
  };

export default FolderSelect;