import React from 'react';
import {NavLink} from 'react-router-dom';
import s from './Header.module.css';
import {ReactComponent as LogoutIcon} from "../assets/icons/logout.svg";
import backIcon from '../assets/icons/back.png';


const Header = (props) => {
    return (
        <header className={s.header}>
            <div className={s.header__content}>
                {props.location.pathname !== '/' && <img className={s.backButton} src={backIcon} onClick={props.goBack} alt="back" />}
                <NavLink className={s.logo} to={'/'}>ГДЗ Крылова</NavLink>
                {props.authorized
                    ? <div className={s.currentSession}>
                            {props.username}
                            <LogoutIcon className={s.logout_button} onClick={props.logout}/>
                        </div>
                    : <div className={s.login_button}>
                        <NavLink to={'/login'}>Войти</NavLink>
                    </div>}
            </div>
        </header>
    );
}

export default Header;
