import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    setSubjects,
    addFiles,
    removeFile,
    setSavingPath,
} from "../../redux/moderationFilesReducer";
import axios from "axios";
import { server } from "../../server-config";
import CreateSubject from "./CreateSubject";

const CreateSubjectContainer = () => {
    const [isRoleLoaded, setIsRoleLoaded] = useState(false);
    const navigate = useNavigate();
    const role = useSelector((state) => state.sessionReducer.user.role);
    useEffect(() => {
        setIsRoleLoaded(true);
    }, [role]);
    useEffect(() => {
        if (isRoleLoaded && role !== "admin") {
            navigate("/not-found");
        }
    }, [role, navigate, isRoleLoaded]);

    const [subjectName, setSubjectName] = useState("");
    const [shortSubjectName, setShortSubjectName] = useState("");
    const [pathName, setPathName] = useState("");
    const [course, setCourse] = useState(null);

    const handleChangeSubjectName = (event) => {
        setSubjectName(event.target.value);
    };
    const handleChangeShortSubjectName = (event) => {
        setShortSubjectName(event.target.value);
    };
    const handleChangePathName = (event) => {
        setPathName(event.target.value);
    };
    const handleChangeCourse = (event) => {
        setCourse(Number(event.target.value));
    };

    const handleCreateSubject = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('subject', subjectName);
        formData.append('shortname', shortSubjectName);
        formData.append('pathname', pathName);
        formData.append('course', course);


        try {
            await axios.post(`${server}/api/create-subject`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("created");
            setSubjectName("");
            setShortSubjectName("");
            setPathName("");
            setCourse(null);
        } catch (error) {
            console.log(error);
            setSubjectName("");
            setShortSubjectName("");
            setPathName("");
            setCourse(null);
        }
    };

    return (
        <CreateSubject
            subjectName={subjectName}
            shortSubjectName={shortSubjectName}
            pathName={pathName}
            course={course}
            handleChangeSubjectName={handleChangeSubjectName}
            handleChangeShortSubjectName={handleChangeShortSubjectName}
            handleChangePathName={handleChangePathName}
            handleChangeCourse={handleChangeCourse}
            handleCreateSubject={handleCreateSubject}
        />
    );
};

export default CreateSubjectContainer;
