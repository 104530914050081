import React, {useState, useEffect} from "react";
import s from './Preloader.module.css';

const Preloader = ({isFetching, children}) => {
    const [loading,
        setLoading] = useState(true);

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    return (
        <div>
            {loading
                ? (
                    <div className={s.preloader}>
                        <div className={s.preloader__spinner}></div>
                    </div>
                )
                : (children)}
        </div>
    );
};

export default Preloader;
