import React from 'react';
import s from './Auth.module.css'

const CreateUser = (props) => {
    return (
        <div>
            <h1>Регистрация</h1>
            <div className={s.auth_form_container}>
                <div className={s.form_information}>
                    {props.state.error.code && <span className={s.error_message}>{props.state.error.message}</span>}
                    {props.state.success.code && <span className={s.success_message}>{props.state.success.message}</span>}
                </div>
                <form className={s.auth_form} onSubmit={props.handleSubmit}>
                    <fieldset>
                        <ul>
                            <li>
                                <label htmlFor="username">Логин:</label>
                                <input
                                    type="text"
                                    name="username"
                                    value={props.state.username || ''}
                                    onChange={props.handleUsernameChange}
                                    required/>
                            </li>
                            <li>
                                <label htmlFor="password">Пароль:</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={props.state.password || ''}
                                    onChange={props.handlePasswordChange}
                                    required/>
                            </li>
                        </ul>
                        <button type="submit">Зарегистрироваться</button>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export default CreateUser;