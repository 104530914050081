import React from 'react';
import s from "./CreateFolder.module.css";
import FolderItem from './FolderItem';

const FolderTree = ({treeData}) => {
    return (
        <div className={s.tree}>
            {treeData.map(data => (<FolderItem
                key={data.id}
                data={data}
                subject={data.pathName}
                type="root"
                folderId={data.rootFolder}/>))}
        </div>
    );
};

export default FolderTree;
