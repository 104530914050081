import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {setSubjects, setSubjectsFetching} from "../../redux/subjectMenuReducer";
import { setCourse } from "../../redux/subjectReducer";
import { useParams } from "react-router-dom";
import SubjectMenu from "./SubjectMenu";
import RenderContent from "../assets/RenderContent";
import { server } from "../../server-config";
import s from "./SubjectMenu.module.css";


const SubjectMenuContainer = () => {
    const dispatch = useDispatch();
    

    const [isRoleLoaded,
        setIsRoleLoaded] = useState(false);
    const [isAdmin,
        setIsAdmin] = useState(false);
    const role = useSelector(state => state.sessionReducer.user.role);
    useEffect(() => {
        setIsRoleLoaded(true);
    }, [role]);
    useEffect(() => {
        if (isRoleLoaded && role === "admin") {
            setIsAdmin(true);
        }
    }, [role, isRoleLoaded]);

    const course = useParams().course;
    const fetchSubjectName = async () => {
        try {
            const response = await axios.get(`${server}/api/get-subjects?course=${course}`);
            dispatch(setSubjects(response.data));
            dispatch(setSubjectsFetching(false));
            dispatch(setCourse(course));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchSubjectName();
    }, [dispatch]);

    const [editShortName, setEditShortName] = useState("");
    const [editableSubjectId, setEditableSubjectId] = useState(null);
    const handleEditName = async (sub) => {
        try {
            const response = await axios.put(`${server}/api/update-subject-name`, {
                id: editableSubjectId,
                shortName: editShortName
            });
            
            if (response.status === 200) {
                setEditShortName(""); // Очищаем поле ввода editShortName
                setEditableSubjectId(null);
                fetchSubjectName();
            } else {
                console.error('Failed to update subject shortName');
            }
        } catch (error) {
            console.error('Error:', error);
        }        
        setEditableSubjectId(null);
    };
    const toggleEdit = (sub) => {
        if (editableSubjectId === sub.id) {
            setEditableSubjectId(null);
        } else {
            setEditShortName(sub.shortName);
            setEditableSubjectId(sub.id);
        }
    };

    const subjects = useSelector((state) => state.subjectMenuReducer.subjects);
    const isFetching = useSelector(state => state.subjectMenuReducer.isFetching);
    const subjectsItems = subjects.map((sub) => (
        <div key={sub.id+sub.name}>
            <div className={s.subject_item_container} key={sub.id}>
                <NavLink key={sub.id+course} to={`/subject/${sub.pathName}/${sub.rootFolder}`}>
                    <RenderContent key={sub.id} icon="folder" name={sub.shortName} isAdmin={isAdmin}/>
                </NavLink>
                {isAdmin && (
                    <button className={s.edit_button} onClick={() => toggleEdit(sub)}>Edit Name</button>
                )}
                <div className={s.editing_menu}>
                    {editableSubjectId === sub.id ? (
                        <input
                            type="text"
                            value={editShortName}
                            onChange={(e) => setEditShortName(e.target.value)}
                        />
                    ) : ""}
                    {editableSubjectId === sub.id && (
                        <button className={s.save_button} onClick={() => handleEditName(sub)}>Save</button>
                    )}
                </div>
            </div>
        </div>
    ));

    return (<SubjectMenu subjectsItems={subjectsItems} isFetching={isFetching}/>);
};

export default SubjectMenuContainer;