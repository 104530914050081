const SET_USERNAME = 'SET_USERNAME';
const SET_PASSWORD = 'SET_PASSWORD';
const SET_ERROR = 'SET_ERROR';
const SET_SUCCESS = 'SET_SUCCESS';

let initialState = {
    username: '',
    password: '',
    error: {
        code: null,
        message: null
    },
    success: {
        code: null,
        message: null
    }
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload
            };
        case SET_PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case SET_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload
                }
            };
        case SET_SUCCESS:
            return {
                ...state,
                success: {
                    ...state.success,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

export const setUsername = (username) => ({type: SET_USERNAME, payload: username});
export const setPassword = (password) => ({type: SET_PASSWORD, payload: password});

export const setError = (payload) => {
    const {
        code = null,
        message = null
    } = payload || {};
    return {
        type: SET_ERROR,
        payload: {
            code,
            message
        }
    };
};
export const setSuccess = (payload) => {
    const {
        code = null,
        message = null
    } = payload || {};
    return {
        type: SET_SUCCESS,
        payload: {
            code,
            message
        }
    };
};

export default authReducer;
