const SET_FILE = "SET_FILE";
const SET_INPUT_FILENAME = "SET_INPUT_FILENAME";
const SET_COURSE = "SET_COURSE"
const SET_SUBJECTS = "SET_SUBJECTS";
const SET_CURRENT_SUBJECT = "SET_SUBJECT";
const SET_FOLDERS = "SET_FOLDERS";
const SET_CURRENT_FOLDER_NAME = "SET_FOLDER_NAME";
const SET_CURRENT_FOLDER_ID = "SET_FOLDER_ID";
const SET_ERROR = "SET_ERROR";
const SET_SUCCESS = "SET_SUCCESS";
const CLEAR_FORM = "CLEAR_FORM";

let initialState = {
    file: {
        fileUrl: null,
        filename: null,
    },
    inputFileName: "",
    course: null,
    subjects: [],
    folders: [],
    currentSubject: "",
    currentFolderName: "",
    currentFolderId: "",
    error: {
        code: null,
        message: null,
    },
    success: {
        code: null,
        message: null,
    },
};

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILE:
            return {
                ...state,
                file: {
                    ...state.file,
                    ...action.payload,
                },
            };
        case SET_INPUT_FILENAME:
            return {
                ...state,
                inputFileName: action.payload,
            };
        case SET_COURSE:
            return {
                ...state,
                course: action.payload,
            };
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload,
            };
        case SET_CURRENT_SUBJECT:
            return {
                ...state,
                currentSubject: action.payload,
            };
        case SET_FOLDERS:
            return {
                ...state,
                folders: action.payload,
            };
        case SET_CURRENT_FOLDER_NAME:
            return {
                ...state,
                currentFolderName: action.payload,
            };
        case SET_CURRENT_FOLDER_ID:
            return {
                ...state,
                currentFolderId: action.payload,
            };
        case SET_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                },
            };
        case SET_SUCCESS:
            return {
                ...state,
                success: {
                    ...state.success,
                    ...action.payload,
                },
            };
        case CLEAR_FORM:
            return {
                ...initialState,
                error: {
                    ...state.error,
                },
                success: {
                    ...state.success,
                },
            };
        default:
            return state;
    }
};

export const setFile = ({ fileUrl, filename }) => ({
    type: SET_FILE,
    payload: {
        fileUrl,
        filename,
    },
});
export const setInputFileName = (inputFilename) => ({
    type: SET_INPUT_FILENAME,
    payload: inputFilename,
});

export const setCourse = (course) => {
    return { type: SET_COURSE, payload: course };
};

export const setSubjects = (subjects) => {
    return { type: SET_SUBJECTS, payload: subjects };
};

export const setSubject = (subject) => ({
    type: SET_CURRENT_SUBJECT,
    payload: subject,
});

export const setFolders = (folders) => {
    return { type: SET_FOLDERS, payload: folders };
};
export const setFolderName = (folder) => ({
    type: SET_CURRENT_FOLDER_NAME,
    payload: folder,
});
export const setFolderId = (folderId) => ({
    type: SET_CURRENT_FOLDER_ID,
    payload: folderId,
});

export const setError = (payload) => {
    const { code = null, message = null } = payload || {};
    return {
        type: SET_ERROR,
        payload: {
            code,
            message,
        },
    };
};
export const setSuccess = (payload) => {
    const { code = null, message = null } = payload || {};
    return {
        type: SET_SUCCESS,
        payload: {
            code,
            message,
        },
    };
};

export const clearForm = () => {
    return { type: CLEAR_FORM };
};

export default uploadReducer;
