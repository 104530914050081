import React from 'react';
import s from './Auth.module.css'
import {NavLink} from 'react-router-dom'

const AuthPage = (props) => {
    return (
        <div>
            <h1>Вход в систему</h1>
            <div className={s.auth_form_container}>
                <div className={s.auth_form_information}>
                    {props.state.error.code && <span className={s.error_message}>{props.state.error.message}</span>}
                </div>
                <div className={s.form_container}>
                    <form className={s.auth_form} onSubmit={props.handleSubmit}>
                        <fieldset>
                            <ul>
                                <li>
                                    <label htmlFor="username">Логин:</label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={props.state.username || ''}
                                        onChange={props.handleUsernameChange}
                                        required/>
                                </li>
                                <li>
                                    <label htmlFor="password">Пароль:</label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={props.state.password || ''}
                                        onChange={props.handlePasswordChange}
                                        required/>
                                </li>
                            </ul>
                            <button type="submit">Войти</button>
                        </fieldset>
                    </form>
                    <div className={s.linkContainer}>
                        <div className={s.linkText}>Нет аккаунта?</div>
                        <NavLink to="/registration" className={s.registerLink}>Зарегистрироваться</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;