import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import Header from './Header';
import {setToken, setUserInfo} from '../../redux/sessionReducer';

const HeaderContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const authorized = useSelector((state) => state.sessionReducer.user.authorized);
    const username = useSelector((state) => state.sessionReducer.user.username);
    const goBack = () => {
        navigate(-1);
    }

    const logout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('username');
        dispatch(setUserInfo(null, false, null));
        dispatch(setToken(null));
        navigate("/")
    }

    return (<Header
        location={location}
        goBack={goBack}
        authorized={authorized}
        username={username}
        logout={logout}/>);
}

export default HeaderContainer;
