import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setToken, setUserInfo } from '../redux/sessionReducer';

const SessionChecker = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');

    if (token) {
      dispatch(setUserInfo(username, true, role));
      dispatch(setToken(token));
    }
  }, [dispatch]);

  return null; // Компонент не рендерит никакого содержимого
};

export default SessionChecker;
