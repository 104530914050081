import React from "react";
import {NavLink} from "react-router-dom";
import s from "./SubjectMenu.module.css";
import RenderContent from "../assets/RenderContent";

const CourseMenu = (props) => {
    return (
        <div className={s.subject__content}>
            <h1 className={s.subject__title}>Выберите курс</h1>
            <div className={s.subject__sections}>
                <div className={s.subject_item_container}>
                    <NavLink to={`/subject-menu/1`}>
                        <RenderContent icon="folder" name="1 Курс"/>
                    </NavLink>
                </div>
                <div className={s.subject_item_container}>
                    <NavLink to={`/subject-menu/2`}>
                        <RenderContent icon="folder" name="2 Курс"/>
                    </NavLink>
                </div>
                <div className={s.subject_item_container}>
                    <NavLink to={`/subject-menu/3`}>
                        <RenderContent icon="folder" name="3 Курс"/>
                    </NavLink>
                </div>
                <div className={s.subject_item_container}>
                    <NavLink to={`/subject-menu/4`}>
                        <RenderContent icon="folder" name="4 Курс"/>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default CourseMenu;