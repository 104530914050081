import React from "react";
import s from "./RenderContent.module.css";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import { ReactComponent as ArticlesIcon } from "./icons/articles.svg";

const Content = ({ icon, name }) => {
  return (
    <div className={s.subject__sections_item}>
      {icon === "folder" ? (
        <FolderIcon className={s.subject__item_icon} />
      ) : (
        <ArticlesIcon className={s.subject__item_icon} />
      )}
      <div className={s.subject__item_name}>{name}</div>
    </div>
  );
};

export default Content;
