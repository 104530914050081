import React from "react";
import s from "./MainPage.module.css";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const MainPage = () => {
    const authorized = useSelector(state => state.sessionReducer.user.authorized);
    const role = useSelector(state => state.sessionReducer.user.role);
    const navigate = useNavigate();
    const handleNavigateUpload = () => {
        if (authorized) {
            navigate('/upload-materials'); // Перенаправляем на страницу загрузки материалов, если пользователь авторизован
        } else {
            navigate('/login'); // Перенаправляем на страницу авторизации, если пользователь не авторизован
        }
    };
    const handleNavigateFolders = () => {
        if (role === "admin") {
            navigate('/create-folder'); // Перенаправляем на страницу загрузки материалов, если пользователь авторизован
        } else {
            navigate('/not-found'); // Перенаправляем на страницу авторизации, если пользователь не авторизован
        }
    };
    const handleNavigateModeration = () => {
        if (role === "admin") {
            navigate('/moderation'); // Перенаправляем на страницу загрузки материалов, если пользователь авторизован
        } else {
            navigate('/not-found'); // Перенаправляем на страницу авторизации, если пользователь не авторизован
        }
    };
    const handleNavigateSubjects = () => {
        if (role === "admin") {
            navigate('/create-subject'); // Перенаправляем на страницу загрузки материалов, если пользователь авторизован
        } else {
            navigate('/not-found'); // Перенаправляем на страницу авторизации, если пользователь не авторизован
        }
    };
    return (
        <div className={s.main__content}>
            <h1 className={s.main__title}>Выберите действие</h1>
            <div className={s.buttons_container}>
                <NavLink to={'course-menu/'}>
                    <div className={s.main_button}>
                        Выбрать предмет
                    </div>
                </NavLink>
                <div className={s.main_button} onClick={handleNavigateUpload}>
                    Загрузить материалы
                </div>
                {role === "admin" && <div className={s.main_button} onClick={handleNavigateModeration}>
                    Модерация загрузок
                </div>}
                {role === "admin" && <div className={s.main_button} onClick={handleNavigateFolders}>
                    Управление папками
                </div>}
                {role === "admin" && <div className={s.main_button} onClick={handleNavigateSubjects}>
                    Управление предметами
                </div>}
            </div>
        </div>
    );
};

export default MainPage;