const SET_SUBJECTS = "SET_SUBJECTS"
const SET_TREE = "SET_TREE"
const SET_FETCHING = "SET_TREE_FETCHING"
const SHOW_FORM = "SHOW_FORM"
const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER"
const SET_FOLDER_NAME = "SET_FOLDER_NAME"
const SET_FOLDER_SHORT_NAME = "SET_FOLDER_SHORT_NAME"
const SET_IS_CREATED = "SET_IS_CREATED"

let initialState = {
    subjects: [],
    tree: [],
    isFetching: true,
    isOpen: false,
    isCreated: false,
    selectedFolder: {
        subject: null,
        type: null, // root or child
        id: null,
        name: "",
        shortName: ""
    }
};

const createFolderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            }
        case SET_TREE:
            return {
                ...state,
                tree: action.payload
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case SHOW_FORM:
            return {
                ...state,
                isOpen: action.payload
            };
        case SET_SELECTED_FOLDER:
            return {
                ...state,
                selectedFolder: {
                    ...state.selectedFolder,
                    subject: action.payload.subject,
                    type: action.payload.type,
                    id: action.payload.id
                }
            };
        case SET_FOLDER_NAME:
            return {
                ...state,
                selectedFolder: {
                    ...state.selectedFolder,
                    name: action.payload
                }
            };
        case SET_FOLDER_SHORT_NAME:
            return {
                ...state,
                selectedFolder: {
                    ...state.selectedFolder,
                    shortName: action.payload
                }
            };
        case SET_IS_CREATED:
            return {
                ...state,
                isCreated: action.payload
            }
        default:
            return state;
    }
};

export const setSubjects = (subjects) => {
    return {type: SET_SUBJECTS, payload: subjects};
};

export const setTree = (tree) => {
    return {type: SET_TREE, payload: tree};
};

export const setFetching = (isFetching) => {
    return {type: SET_FETCHING, payload: isFetching};
};

export const showForm = (show) => {
    return {type: SHOW_FORM, payload: show};
};

export const setSelectedFolder = (subject, type, id) => {
    return {type: SET_SELECTED_FOLDER, payload: {subject, type, id}};
};

export const setFolderName = (name) => {
    return {type: SET_FOLDER_NAME, payload: name};
};

export const setShortName = (shortName) => {
    return {type: SET_FOLDER_SHORT_NAME, payload: shortName};
};

export const setIsCreated = (isCreated) => {
    return {type: SET_IS_CREATED, payload: isCreated};
};

export default createFolderReducer;
