import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setSubjects, addFiles, removeFile, setSavingPath} from "../../redux/moderationFilesReducer";
import s from "./ModerationFiles.module.css";
import axios from "axios";
import { server } from "../../server-config";

const ModerationFiles = () => {
    const dispatch = useDispatch();
    const [isRoleLoaded,
        setIsRoleLoaded] = useState(false);
    const navigate = useNavigate();
    const role = useSelector(state => state.sessionReducer.user.role);
    useEffect(() => {
        setIsRoleLoaded(true);
    }, [role]);
    useEffect(() => {
        if (isRoleLoaded && role !== "admin") {
            navigate("/not-found");
        }
    }, [role, navigate, isRoleLoaded]);

    const subjects = useSelector(state => state.ModerationFilesReducer.subjects);
    useEffect(() => {
        const fetchSubjects = async() => {
            try {
                const courses = [1, 2, 3, 4];
                const response = await Promise.all(courses.map(async (course) => {
                    return await axios.get(`${server}/api/get-subjects?course=${course}`);
                }));
                const subjects = response.map(res => res.data);
                if (subjects.length > 0)
                    dispatch(setSubjects(subjects));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchSubjects();
    }, [dispatch]);
    const files = useSelector(state => state.ModerationFilesReducer.files);
    useEffect(() => {
        const fetchData = async() => {
            for (let course of subjects) {
                if (course && course.length > 0) {
                    for (let subject of course) {
                        try {
                            const contentResponse = await axios.get(`${server}/api/get-awaiting-files?subject=${subject.pathName}`);
                            dispatch(addFiles(subject.pathName, contentResponse.data));
                        } catch (error) {
                            console.error('Error fetching data for subject', subject.pathName, ':', error);
                        }
                    }
                }  
            }
        };

        fetchData();
    }, [dispatch, subjects]);

    useEffect(() => {
        const updatePaths = async() => {
            for (let course of subjects) {
                if (course && course.length > 0){
                    for (let subject of course) {
                        if (files[subject.pathName]) {
                            for (let file of files[subject.pathName]) {
                                if (!file.savingPath) {
                                    try {
                                        const pathResponse = await axios.get(`${server}/api/get-moderation-path?subject=${subject.pathName}&id=${file.currentFolderId}`);
                                        dispatch(setSavingPath(subject.pathName, file.id, pathResponse.data));
                                    } catch (error) {
                                        console.error('Error fetching path for file', file.id, ':', error);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };

        if (subjects.length > 0) {
            updatePaths();
        }
    }, [dispatch, subjects, files]);

    const handleAccept = async(subject, fileId, path) => {
        try {
            const response = await axios.put(`${server}/api/accept-file?subject=${subject}&id=${fileId}&path=${path}`);
            if (response.status === 200) {
                dispatch(removeFile(subject, fileId));
            }
        } catch (error) {
            console.error('Error accepting file:', error);
        }
    };

    const handleReject = async(subject, fileId) => {
        try {
            const response = await axios.delete(`${server}/api/reject-file?subject=${subject}&id=${fileId}`);
            if (response.status === 200) {
                dispatch(removeFile(subject, fileId));
            }
        } catch (error) {
            console.error('Error rejecting file:', error);
        }
    };
    const awaitingFiles = Object
        .keys(files)
        .filter(subject => files[subject].length > 0)
        .map(subject => (
            <div className={s.awaitingSubject} key={subject}>
                <div>{subject}</div>
                <ol>
                    {files[subject].map(file => (
                        <li key={file.id}>
                            <a
                                href={`${server}/api/download-moderation?subject=${subject}&fileName=${file.filename}&folder=${file.currentFolderId}`}
                                download
                                target="_blank"
                                rel="noopener noreferrer">
                                <div>{file.filename}</div>
                            </a>
                            <div className={s.file_path}>
                                <span>{file.savingPath}</span>
                            </div>
                            <button onClick={() => handleAccept(subject, file.id, file.savingPath)}>Принять</button>
                            <button onClick={() => handleReject(subject, file.id, file.savingPath)}>Отклонить</button>
                        </li>
                    ))}
                </ol>
            </div>
        ));
    return (
        <div>
            <h1>Запросы на загрузку файлов</h1>
            {awaitingFiles}
        </div>
    )
};

export default ModerationFiles;