import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <p>404. Тут пока ничего нету</p>
        </div>
    );
};

export default NotFoundPage;
