const SET_SUBJECT_NAME = "SET_SUBJECT_NAME"
const SET_CONTENT = "SET_CONTENT"
const SET_FETCHING = "SET_FETCHING"
const SET_PATH = "SET_PATH"
const SET_COURSE = "SET_COURSE"

const initialState = {
    subjectId: null,
    subjectName: "",
    course: null,
    content: [],
    path: [],
    isFetching: true
};

const subjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBJECT_NAME:
            return {
                ...state,
                subjectName: action.payload
            };
        case SET_CONTENT:
            return {
                ...state,
                content: action.payload
            };
        case SET_PATH:
            return {
                ...state,
                path: action.payload
            };
        case SET_COURSE:
            return {
                ...state,
                course: action.payload
            };
        case SET_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        default:
            return state;
    }
};

export const setSubjectName = (name) => {
    return {type: SET_SUBJECT_NAME, payload: name};
};

export const setPath = (path) => {
    return {type: SET_PATH, payload: path};
};

export const setContent = (content) => {
    return {type: SET_CONTENT, payload: content};
};

export const setCourse = (course) => {
    return {type: SET_COURSE, payload: course};
}

export const setFetching = (isFething) => {
    return {type: SET_FETCHING, payload: isFething};
};

export default subjectReducer;
