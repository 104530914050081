import React from "react";
import s from "./Subject.module.css";
import Preloader from "../assets/Preloader";

const Subject = (props) => {
    return (
        <Preloader isFetching={props.isFetching}>
            <div className={s.subject__content}>
                <h1 className={s.subject__title}>{props.subjectName}</h1>
                <div className={s.subject__path}>{props.displayPath}</div>
                <div className={s.subject__sections}>{props.items}</div>
            </div>
        </Preloader>
    );
};

export default Subject;