import React, {useEffect} from 'react';
import axios from 'axios';
import s from "./CreateFolder.module.css";
import {showForm, setSelectedFolder, setFolderName, setShortName, setIsCreated} from '../../redux/createFolderReducer';
import {useDispatch, useSelector} from 'react-redux';
import { server } from '../../server-config';

const FolderItem = ({data, onCreateSuccess, subject, type, folderId}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.createFolderReducer.isOpen);
    const selectedFolder = useSelector(state => state.createFolderReducer.selectedFolder);
    const folderName = useSelector(state => state.createFolderReducer.selectedFolder.name);
    const shortName = useSelector(state => state.createFolderReducer.selectedFolder.shortName);
    const handleCreateFolder = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${server}/api/create-folder`, {
                selectedFolder: selectedFolder
            });

            if (response.status === 200) {
                dispatch(setFolderName(''));
                dispatch(setShortName(''));
                dispatch(showForm(false));
                dispatch(setSelectedFolder(null, null, null));
                dispatch(setIsCreated(true));
                return;
            }
        } catch (error) {
            console.error("Ошибка при создании папки:", error);
        }
    };

    const handleOpenFolder = () => {
        if (selectedFolder.id === folderId && isOpen) {
            dispatch(showForm(false));
            dispatch(setSelectedFolder(null, null, null));
        } else {
            dispatch(setFolderName(""));
            dispatch(showForm(true));
            dispatch(setSelectedFolder(subject, type, folderId));
        }
    }
    useEffect(() => {
        if (!isOpen) {
            dispatch(showForm(false));
            dispatch(setSelectedFolder(null, null, null));
        }
    }, [isOpen, dispatch]);

    const handleFolderNameChange = (event) => {
        dispatch(setFolderName(event.target.value));
    };
    const handleFolderShortNameChange = (event) => {
        dispatch(setShortName(event.target.value));
    };
    return (
        <div>
            <div
                className={`${s.tree_item} ${selectedFolder && selectedFolder.id === folderId
                ? s.active
                : ''}`}
                onClick={handleOpenFolder}>
                <span className={s.tree_item_text}>{data.shortName || data.filename}</span>
                <button className={s.add_folder_button}>+</button>
            </div>
            {(isOpen && (selectedFolder.id === folderId)) && (
                <form className={s.tree_form} onSubmit={handleCreateFolder}>
                    <ul>
                        <li>
                            <label>Имя папки</label>
                            <input
                                type="text"
                                value={folderName || ''}
                                onChange={handleFolderNameChange}
                                required/></li>
                        <li>
                            <label>shortName</label>
                            <input
                                type="text"
                                value={shortName || ''}
                                onChange={handleFolderShortNameChange}
                                required/></li>
                    </ul>

                    <button type="submit">Создать</button>
                </form>
            )}
            {data.children && data.children.length > 0 && (
                <div className={s.tree_item_node}>
                    {data
                        .children
                        .map(child => (<FolderItem
                            key={child.id}
                            data={child}
                            subject={subject}
                            type="child"
                            folderId={child.content}
                            onCreateSuccess={onCreateSuccess}/>))}
                </div>
            )}
        </div>
    );
};

export default FolderItem;
